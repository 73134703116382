<template>
  <div>
    <div class="alert alert-custom alert-light-info fade show" role="alert">
      <div class="alert-icon"><i class="fa fa-exclamation-triangle"></i></div>
      <div class="alert-text">
        Para registrar la presencia de propaganda electoral ingrese a la ficha
        del local asociado. En esta sección sólo se listan los incidentes
        registrados.
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-form>
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label">
                  <i class="fas fa-filter"></i> Filtros
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-md-3">
                  <label class="font-weight-bold">Código de Local</label>
                  <b-form-input
                    id="input-1"
                    v-model="filter.codigo"
                    required
                    placeholder="Código"
                  ></b-form-input>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold">Local de votación</label>
                  <b-form-input
                    id="input-2"
                    v-model="filter.descripcion"
                    required
                    placeholder="Nombre o dirección"
                  ></b-form-input>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold">Región</label>
                  <select class="form-control" v-model="filter.regionId">
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="region in regiones"
                      :value="region.regionId"
                      :key="region.regionId"
                      >{{ region.nombre }}</option
                    >
                  </select>
                </div>
                <div class="col-md-3">
                  <label class="font-weight-bold"> Sub región</label>
                  <select class="form-control" v-model="filter.subRegionId">
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="subRegion in subRegiones"
                      :value="subRegion.subRegionId"
                      :key="subRegion.subRegionId"
                      >{{ subRegion.nombre }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="font-weight-bold"> Organización Política</label>
                  <select
                    class="form-control"
                    v-model="filter.partidoPoliticoParamId"
                  >
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="partido in partidos"
                      :value="partido.parametroId"
                      :key="partido.parametroId"
                      >{{ partido.descripcion }}</option
                    >
                  </select>
                </div>
              </div>
            </div>

            <div class="card-footer padding-t-b">
              <div class="col-md-12">
                <a
                  href="#"
                  class="btn btn-primary font-weight-bold mr-2 float-right"
                  @click.prevent="fetchPropagandasElectorales()"
                  ><i class="fa fa-search"></i> Buscar</a
                >
                <a
                  href="#"
                  class="btn btn-success font-weight-bold mr-2 float-right"
                  @click.prevent="downloadReportePropagandaElectoral()"
                  ><i class="la la-file-excel"></i> Reporte</a
                >
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                <i class="fa fa-id-badge fa-fw "></i> Propagandas Electorales
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-hover"
              >
                <thead>
                  <tr>
                    <th>
                      Local / Dirección
                    </th>
                    <th>
                      Sub región / Región
                    </th>
                    <th>
                      Organización Política
                    </th>
                    <th>
                      Ubicación
                    </th>
                    <th>
                      Hallazgos
                    </th>
                    <!--<th class="rotate-header">
                      <div><span>Afiche (pegatina)</span></div>
                    </th>
                    <th class="rotate-header">
                      <div><span>Carteles</span></div>
                    </th>
                    <th class="rotate-header">
                      <div><span>Militantes</span></div>
                    </th>
                    <th class="rotate-header">
                      <div><span>Otros</span></div>
                    </th>
                    <th class="align-bottom">
                      <div><span>Descripción</span></div>
                    </th>-->
                    <!--<th class="datatable-cell text-center">Opciones</th>-->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="propaganda in propagandas"
                    :key="propaganda.localVotacionFichaPropagandaElectoralId"
                  >
                    <td>
                      {{ propaganda.nombreLocalVotacion }}<br />
                      <small class="text-muted">{{
                        propaganda.direccionLocalVotacion
                      }}</small>
                    </td>
                    <td>
                      {{ propaganda.nonbreSubRegion }}<br />
                      <small class="text-muted">{{
                        propaganda.nombreRegion
                      }}</small>
                    </td>
                    <td>
                      {{ propaganda.nombrePartidoPolitico }}
                    </td>

                    <td>
                      <span
                        v-if="propaganda.ubicacionPropaganda == 'IN'"
                        class="badge badge-light"
                        ><span
                          class="label label-xl label-dot label-primary"
                        ></span>
                        Dentro</span
                      >
                      <span
                        v-if="propaganda.ubicacionPropaganda == 'OUT'"
                        class="badge badge-light"
                        ><span
                          class="label label-xl label-dot label-success"
                        ></span>
                        Fuera</span
                      >
                    </td>
                    <td>
                      <div v-if="propaganda.tieneAfichePegatina == true">
                        <span
                          class="label label-xl label-dot label-primary"
                        ></span>
                        <small>Afiche (pegatina)</small>
                      </div>
                      <div v-if="propaganda.tienePintasCartelesGrandes == true">
                        <span
                          class="label label-xl label-dot label-warning"
                        ></span>
                        <small>Carteles</small>
                      </div>
                      <div
                        v-if="propaganda.tieneMilitantesConDistintivos == true"
                      >
                        <span
                          class="label label-xl label-dot label-info"
                        ></span>
                        <small>Militantes</small>
                      </div>
                      <div v-if="propaganda.tieneOtro == true">
                        <span
                          class="label label-xl label-dot label-dark"
                        ></span>
                        <small>Otro: {{ propaganda.descripcionOtro }}</small>
                      </div>
                    </td>
                    <!--<th class="rotate-header">
                      <div><span>Afiche (pegatina)</span></div>
                    </th>
                    <th class="rotate-header">
                      <div><span>Carteles</span></div>
                    </th>
                    <th class="rotate-header">
                      <div><span>Militantes</span></div>
                    </th>
                    <th class="rotate-header">
                      <div><span>Otros</span></div>
                    </th

                    <td>
                      <span v-if="propaganda.tieneAfichePegatina == true"><i class="fa fa-check text-success"></i></span>
                      <span v-if="propaganda.tieneAfichePegatina == false"><i class="fa fa-times text-muted"></i></span>
                    </td>
                    <td>
                      <span v-if="propaganda.tienePintasCartelesGrandes == true"><i class="fa fa-check text-success"></i></span>
                      <span v-if="propaganda.tienePintasCartelesGrandes == false"><i class="fa fa-times text-muted"></i></span>
                    </td>
                    <td>
                      <span v-if="propaganda.tieneMilitantesConDistintivos == true"><i class="fa fa-check text-success"></i></span>
                      <span v-if="propaganda.tieneMilitantesConDistintivos == false"><i class="fa fa-times text-muted"></i></span>
                    </td>
                    <td>
                      <span v-if="propaganda.TieneOtro == true"><i class="fa fa-check text-success"></i></span>
                      <span v-if="propaganda.TieneOtro == false"><i class="fa fa-times text-muted"></i></span>
                    </td>
                    <td>
                      {{ propaganda.descripcionOtro }}
                    </td>>-->
                    <!--<td class="datatable-cell text-center">
                      <b-dropdown>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                          href="#"
                          @click.prevent="doMdlActosViolencia(actoViolencia)"
                          ><span class="navi-icon"
                            ><i class="la la-info-circle text-dark"></i>
                            Detalle</span
                          ></b-dropdown-item
                        >
                      </b-dropdown>
                    </td>-->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        id="download-modal"
        size="md"
        title="Descargar Reporte"
        hide-footer
      >
        <div v-if="download.hasDownloadResult">
          <div class="mb-3">
            <p>
              Utilice el siguiente enlace para descargar o compartir el reporte:
            </p>
            <div class="card p-2 mb-2 border bg-light">
              {{ filesUrl + download.reportUrl }}
            </div>
            <a
              class="btn btn-default"
              :href="`${filesUrl + download.reportUrl}`"
              target="_blank"
              ><i class="fa fa-download fa-fw"></i> Descargar reporte</a
            >
          </div>
        </div>
        <!--<div slot="modal-footer" class="d-flex justify-content-between w-100">
        <div class="float-left"></div>
        <b-button variant="primary" class="float-right" @click="$bvModal.hide('preview-modal')">
          Cerrar
        </b-button>
      </div>-->
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
th.rotate-header {
  /* Something you can count on */
  height: 80px;
  white-space: nowrap;
}

th.rotate-header > div {
  transform: 
    /* Magic Numbers */ translate(25px, 21px)
    /* 45 is really 360 - 45 */ rotate(315deg);
  width: 30px;
}
th.rotate-header > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import Swal from "sweetalert2";
/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "list-propaganda-electoral",
  components: {},
  props: {},
  data: () => ({
    propagandas: [],
    pagination: {
      pageSize: 10,
    },
    filter: {
      regionId: null,
      subRegionId: null,
      partidoPoliticoParamId: null,
      codigo: "",
      descripcion: "",
    },
    regiones: [],
    subRegiones: [],
    partidos: [],
    download: {
      hasDownloadResult: false,
      reportUrl: null,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    fetchPropagandasElectorales: function() {
      var model = {
        ...this.pagination,
        ...this.filter,
      };

      //alert(JSON.stringify(model));

      this.$http
        .post(
          this.apiRoute(
            "ElectoralPropaganda",
            "ListLocalVotacionFichaPropagandaElectoral"
          ),
          model
        )
        .then(function(response) {
          this.propagandas = response.body.data.items;
          this.pagination = response.body.data.pagination;
        });
    },
    fetchRegiones: function() {
      var modelRegion = {};
      this.$http
        .post(this.apiRoute("Region", "ListRegiones"), modelRegion)
        .then(function(response) {
          this.regiones = response.body.data.items;
        });
    },
    fetchSubRegiones: function(regionId) {
      this.$http
        .get(this.apiRoute("Region", "ListSubRegionesPorRegion"), {
          params: {
            RegionId: regionId,
          },
        })
        .then(function(response) {
          this.subRegiones = response.body.data.items;
        });
    },
    fetchPartidosPoliticos: function() {
      debugger;
      var code = "PARTIDO_POLITICO";
      this.$http
        .get(this.apiRoute("Parameter", "ListParametros"), {
          params: {
            Code: code,
          },
        })
        .then(function(response) {
          this.partidos = response.body.data;
        });
    },
    downloadReportePropagandaElectoral: function() {
      var model = {
        ...this.filter,
      };

      this.showLoading();
      this.download.hasDownloadResult = false;

      this.$http
        .post(
          this.apiRoute("ElectoralPropaganda", "GetReportePropagandaElectoral"),
          model
        )
        .then(function(response) {
          this.download.hasDownloadResult = true;
          this.download.reportUrl = response.body.data.reportUrl;
          this.$bvModal.show("download-modal");
          this.showLoading(false);
        });
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "filter.regionId": function(newValue, oldValue) {
      this.fetchSubRegiones(newValue);
    },
  },
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Propagandas Electorales" },
    ]);
    this.fetchPartidosPoliticos();
    this.fetchRegiones();
    this.fetchPropagandasElectorales();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
